import React from 'react'

const About = () => {
  return (
     <>
     <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title mb-4">
                        <h5 className="position-relative d-inline-block text-primary text-uppercase">About Us</h5>
                        <h1 className="display-5 mb-0 logofont">Suresh Paper Pvt Ltd</h1>
                    </div>
                    <h4 className="text-body fst-italic mb-4 textj">India imports a large quantity of newsprint of all makes and specifications to meet the growing demand of print media. In this direction, for the dissemination of news and entertainment in the print media.</h4>
                    <p className="mb-4 textj"><span className="logofont">Suresh Paper Pvt. Ltd.</span> has been making its humble albeit substantial contribution during the last more than three decades. Being one of the most reputed Companies in this field, functioning from the country’s Capital, its reach, diversity of product supply, reliability and credibility among its large number of clients and its ability to cater to the needs of the media, especially small and medium newspapers and periodicals, is second to none.
                    <span className="logofont">Suresh Paper Pvt. Ltd.</span> has developed a unique network of supply and import channels from almost all major newsprint producing countries of the world: notable among them being North American countries like Canada & USA; European countries like Sweden, Norway, Finland & Germany, Russia, Far Eastern countries like Korea, Singapore & Japan. Besides, we also supply substantial quantity of indigenously produced newsprint to our clients. Having developed a systemic approach and time-bound operational norms, we have the organizational and infrastructural capability of efficient time and functional management, port & customs operationalisation, road transport, insurance and other related functions thus making it possible for the imported product to reach at the doorstep of the Actual User in all major consuming centers of the country, with so to say, sharp-edged smoothness of a blade.</p>
                    <p className="mb-4 textj"> These functional achievements in a highly competitive environs bespeak of our ability to cope up with any market situation and face up the challenges which lie ahead. Being endowed with a team of dedicated young entrepreneurs and a set of devoted workers, an office equipped with all modern means of communication and dissemination, we have developed functional efficiency at a level of which we are rightly proud of. Hallmarks of our functioning are transparency in all business dealings and ultimate timeliness of operations to best serve the end-users and their satisfaction with our inputs and services.</p>
                    
                          </div>
                <div className="col-lg-5" >
                    <div className="position-relative  ">
                        <img alt="sureshpaper" className="position-absolute w-100 objfit rounded wow zoomIn" data-wow-delay="0.9s" src="images/abt.jpg"   />
                    </div>
                </div>
            </div>
        </div>
    </div>
    
<br/>
<br/><br/>

</>
  )
}

export default About