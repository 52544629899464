import React from 'react'

const Products = () => {
  return (
    <>
     <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container">
            <div class="row g-5 mb-5">
                
                <div class="col-lg-12">
                    <div class="section-title mb-5">
                        <h5 class="position-relative d-inline-block text-primary text-uppercase">Our Products</h5>
                        <h1 class="display-5 mb-0">We Offer The Best Quality  Products  </h1>
                    </div>
                    <div class="row g-5">
                        
                      <div class="margintopbottom" id="one">
                      </div>

                       <div class="col-md-12 service-item wow zoomIn"  data-wow-delay="0.6s">
                            
                        <div class="row">
                            <div class="col-md-4">
                               <div class="rounded-top overflow-hidden">
                                  <img class="img-fluid" src="images/Standard%20newsprint%20paper.jpg" alt="sureshpaper" />
                               </div>
                               <div class="position-relative bg-light rounded-bottom text-center p-4">
                                   <h5 class="m-0">Standard Newsprint Paper</h5>
                               </div>
                         </div>
                             <div class="col-md-8">
                                <h1 class="mb-3">Standard Newsprint Paper</h1>
                                <p>Standard Newsprint suitable for printing on Coldset Offset Machines with excellent printing results and runnability.</p>
                                <ul>
                                  <li> Raw Material: 100% recycled fibres or 100% virgin fibres Comes in reels as per customers specifications</li>
                                  <li>End Use: Newspapers, Supplements and Advertisement materials</li>
                                  <li>Basis Weight: 40 GSM, 41.5 GSM, 42 GSM, 45 GSM, 48.8 GSM & 52 GSM </li>
                                </ul>
                             </div>
                         </div>
                           
                       </div>
                      
                       <div class="margintopbottom" id="two">
                      </div>
                       <div class="col-md-12 service-item wow zoomIn"   data-wow-delay="0.6s">
                            <div class="row">
                                <div class="col-md-4">
                                   <div class="rounded-top overflow-hidden">
                                      <img class="img-fluid" src="images/newspaperfeat.png" alt="sureshpaper" />
                                   </div>
                                   <div class="position-relative bg-light rounded-bottom text-center p-4">
                                   <h5 class="m-0">SC Offset /Glazed Newsprint Paper</h5>
                                   </div>
                                </div>
                                 <div class="col-md-8">
                                 <h1 class="mb-3">SC Offset /Glazed Newsprint Paper</h1>
                                 <p>SC Paper is uncoated high gloss paper having specially designed to be printed on Heat set web off set machines.</p>       
                                                        <ul>  
                                                            <li> Raw Material: 100% recycled fibres or 100% virgin fibres Comes in reels as per customers specifications</li>
                                      <li>End Use: Newspapers, Supplements and Advertisement materials</li>
                                      <li>Basis Weight: 45 GSM-60 GSM </li>
                                        <li>Finish: Gloss, Matt and Silky</li>
                                                        </ul>
                                 </div>
                            </div>
                      </div>

                     
                      <div class="margintopbottom" id="three">
                      </div>
                         <div class="col-md-12 service-item wow zoomIn" data-wow-delay="0.6s">
                            
                            <div class="row">
                                <div class="col-md-4">
                                   <div class="rounded-top overflow-hidden">
                                   <img class="img-fluid" src="images/magazine.jpeg" alt="sureshpaper" />
                                   </div>
                                   <div class="position-relative bg-light rounded-bottom text-center p-4">
                                   <h5 class="m-0">Improved Newsprint</h5>
                                   </div>
                             </div>
                                 <div class="col-md-8">
                                 <h1 class="mb-3">Improved Newsprint</h1>
                                 <p>Improved Paper having specially designed to be printed on Heat set web off set machines.</p>
                     
                     <ul>
                         <li> Raw Material: 100% recycled fibres or 100% virgin fibres Comes in reels as per customers specifications</li>
   <li>End Use: Newspapers, Supplements and Advertisement materials</li>
   <li>Basis Weight: 45 GSM-60 GSM </li>
                     </ul>
                                 </div>
                             </div>
                               
                         </div>
                        
                         <div class="margintopbottom" id="four">
                         </div>
                         <div class="col-md-12 service-item wow zoomIn"  data-wow-delay="0.6s">
                            
                            <div class="row">
                                <div class="col-md-4">
                                   <div class="rounded-top overflow-hidden">
                                   <img class="img-fluid" src="images/writing.jpg" alt="sureshpaper" />
                                   </div>
                                   <div class="position-relative bg-light rounded-bottom text-center p-4">
                                   <h5 class="m-0">Low Weight Coated Paper (LWC)</h5>
                                   </div>
                             </div>
                                 <div class="col-md-8">
                                 
                                 <h1 class="mb-3">Low Weight Coated Paper (LWC)</h1>
                      <p>Low Weight Coated paper available in different basis weights, brightness levels and finish is suitable for Heatset web offset printing & Rotogravure printing.</p>
                     
                  <ul>
                     <li> Raw Material: 100% recycled fibres or 100% virgin fibresComes in reels and sheets as per customers specification</li>
<li>End Use: Newspaper Supplements, Magazines, Catalogues and Advertisement materials</li>
<li>Basis Weight: 48 GSM, 51 GSM, 54 GSM, 57 GSM, 60 GSM, 65 GSM & 70 GSM </li>
 <li>Finish: Gloss, Matt and Silky</li>
                  </ul>
                                 </div>
                             </div>
                               
                         </div>
                        
                         <div class="margintopbottom" id="five">
                         </div>
                         <div class="col-md-12 service-item wow zoomIn"  data-wow-delay="0.6s">
                            
                            <div class="row">
                                <div class="col-md-4">
                                   <div class="rounded-top overflow-hidden">
                                   <img class="img-fluid" src="images/art-paper-n-500x500.jpg" alt="sureshpaper" />
                                   </div>
                                   <div class="position-relative bg-light rounded-bottom text-center p-4">
                                   <h5 class="m-0">Art Paper (C2S)</h5>
                                   </div>
                             </div>
                                 <div class="col-md-8">
                                 <h1 class="mb-3">Art Paper (C2S)</h1>
                      <p>We are the traders for importing all kinds of art paper, map-litho paper and art boards from various countries. Available in the following sizes, our offered products include the following: Art paper</p>
                     
                  <ul>
  <li>90 gsm art paper available size 23x36 and 25x36 in inches</li>
<li>100 gsm art paper available size 23x36 and 25x36 and 30x40 in inches</li>
<li>130 gsm art paper available size 23x36 and 25x36 and 30x40 in inches</li>
                      <li>170 gsm art paper available size 23x36 and 25x36 and 30x40 in inches</li>
                      <li>170 gsm art paper available size 23x36 and 25x36 and 30x40 in inches</li>

                  </ul>
                                 </div>
                             </div>
                               
                         </div>
                        
                         <div class="margintopbottom" id="six">
                         </div>
                         <div class="col-md-12 service-item wow zoomIn"   data-wow-delay="0.6s">
                            
                            <div class="row">
                                <div class="col-md-4">
                                   <div class="rounded-top overflow-hidden">
                                   <img class="img-fluid" src="images/Untitled-design-3.png" alt="sureshpaper" />
                                   </div>
                                   <div class="position-relative bg-light rounded-bottom text-center p-4">
                                   <h5 class="m-0">Cromo Paper (C1S)</h5>
                                   </div>
                             </div>
                                 <div class="col-md-8">
                                 <h1 class="mb-3">Cromo Paper (C1S)</h1>
                                 <p>Our clients can avail from us imported Chromo PaperC1S, which are of classic & super qualities. We ensure to match the requirements of our customers in both sheet and reel form. Being extremely durable and thick, these are highly demanded by our esteemed clients.</p>
                     
                     <ul>               
   <li>Availability of gsm : 50 to 450 gsm</li>
   <li>Availability of size : As Per Customer Requirement</li>
                     </ul> 
                                 </div>
                             </div>
                               
                         </div>
                        
                         <div class="margintopbottom" id="seven">
                         </div>
                         <div class="col-md-12 service-item wow zoomIn"   data-wow-delay="0.6s">
                            
                            <div class="row">
                                <div class="col-md-4">
                                   <div class="rounded-top overflow-hidden">
                                   <img class="img-fluid" src="images/kraft-paper-1534797505-4212260%20(2).jpg" alt="sureshpaper" />
                                   </div>
                                   <div class="position-relative bg-light rounded-bottom text-center p-4">
                                   <h5 class="m-0">Kraft Paper</h5>
                                   </div>
                             </div>
                                 <div class="col-md-8">
                                 <h1 class="mb-3">Kraft Paper</h1>
                                 <p>Kraft Paper come in many varities like <b>Virgin Kraft Liner</b>, <b>White Top Coated and Uncoated KLB</b>, <b>Sack Kraft</b>, <b>Bleached Kraft </b>& Many More.</p>
                 
                 <ul>
  <li>Availability of gsm : 45 to 500 gsm</li>
<li>Availability of size : As Per Customer Requirement</li>
               </ul> 
                                 </div>
                             </div>
                               
                         </div>
                        
                         <div class="margintopbottom" id="eight">
                         </div>
                         <div class="col-md-12 service-item wow zoomIn"   data-wow-delay="0.6s">
                            
                            <div class="row">
                                <div class="col-md-4">
                                   <div class="rounded-top overflow-hidden">
                                   <img class="img-fluid" src="images/H7b362c8ef1df4d47bdbad4ab66a9a8beY.jpg" alt="sureshpaper" />
                                   </div>
                                   <div class="position-relative bg-light rounded-bottom text-center p-4">
                                   <h5 class="m-0">SBS/FBB</h5>
                                   
                                   </div>
                             </div>
                                 <div class="col-md-8">
                                 <h1 class="mb-3">SBS/FBB</h1>
                                 <p> SBS paperboard is a medium density board with good printing properties for graphical and packaging end uses. It can easily be embossed, hot foil stamped, creased or cut. SBS paper is also hygienic with no smell or taste, making it perfect for the packaging of cosmetics, cigarettes, and chocolate.</p>
                 
                 <p>FBB (Folding Box Board) comprises middle layers of mechanical pulp sandwiched between layers of bleached chemical pulp. The top and reverse sides of this board may be coated with mineral, or synthetic pigments.</p>
                     
                    <ul>
 <li>Availability of gsm :  150 to  500 gsm</li>
<li>Availability of size : As Per Customer Requirement</li>
                  </ul> 
                                 </div>
                             </div>
                               
                         </div>
                        
                         <div class="margintopbottom" id="nine">
                         </div>
                         
                         <div class="col-md-12 service-item wow zoomIn"   data-wow-delay="0.6s">
                            <div class="row">
                                <div class="col-md-4">
                                   <div class="rounded-top overflow-hidden">
                                     <img class="img-fluid" src="images/c1s-label-500x500.jpg" alt="sureshpaper" />
                                   </div>
                                   <div class="position-relative bg-light rounded-bottom text-center p-4">
                                   <h5 class="m-0">SCK/CCK</h5>
                                   </div>
                             </div>
                                 <div class="col-md-8">
                                 <h1 class="mb-3">SCK/CCK</h1>
                                 <p>It is coated on one or both sides with a release agent, which provides a release effect against any type of a sticky material such as an adhesive or a mastic
Specification:70-150 GSM</p>
                     
                  <ul>
Industry:
 <li>Pressure-sensitive labels</li>
 <li>Pressure-sensitive tape</li>
 <li>Self-adhesive plastic sheet</li>
 
Methods of Printing:
<li>Web /sheet-fed printing.</li>

                      
                         
                  </ul> 
                                 </div>
                             </div>
                               
                         </div>
                        

                       
                    </div>




                </div>
            </div>
            
        </div>
    </div>
    </>
  )
}

export default Products