import React from 'react'

import Abt from './Abt';
import Location from './Location';
import Product from './Product';
import Slide from './Slide';
import Slider from './Slider';

 
 
const Home = () => {
  return (
    <>
  
    <Slider/>
   
    <Location/>
    
    <Abt/>
     

 
    <Slide/>
     <Product/>
   

  
  

   
    
 
    </>
  )
}

export default Home