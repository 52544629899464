import React from 'react'
import { Link } from "react-router-dom";
const Location = () => {
  return (
    <>
    <div className="container-fluid banner mb-5">
        <div className="container">
            <div className="row gx-0">
                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.1s">
                    <div className="bg-primary d-flex flex-column p-5 h-300"  >
                        <h3 className="text-white mb-3">Our Locations</h3>
                        
                         
                        <div className="d-flex justify-content-between text-white mb-3">
                            <h6 className="text-white mb-0"><i className="fa fa-map"></i> F-24, Street No. 10, Main Parwana Road, Jagatpuri, Delhi-110051, India</h6>
                             
                        </div>
                        
                        <div className="d-flex justify-content-between text-white mb-3">
                            <h6 className="text-white mb-0"><i className="fa fa-map"></i> C-6 & 7, Tronica City Sector, A-2 Loni Ghaziabad, Uttar Pradesh 201102 India</h6>
                            
                        </div>
                        <div className="d-flex justify-content-between text-white mb-3">
                            <h6 className="text-white mb-0"><i className="fa fa-envelope"></i> info@sureshpaper.com</h6>
                            
                        </div>
                        
                    </div>
                </div>
                
                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="bg-secondary d-flex flex-column p-5 h-300"  >
                        <h3 className="text-white mb-3">Make A Call</h3>
                        <p className="text-white">Please do not hesitate to call us if you have further questions.</p>
                        <h2 className="text-white mb-0"><Link  className="text-white" to="tel:+91-7982321829">+91-7982321829</Link></h2>
                        {/* <h2 className="text-white mb-0"><Link  className="text-white" to="tel:+91-11-45693550">+91-11-45693550</Link></h2> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Location