import './App.css';
import Footer from './Components/Footer';
import Header from './Components/Header';
import {  Routes, Route } from "react-router-dom";
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import Warehouse from './Components/Warehouse';
import Products from './Components/Products';
import Gallery from './Components/Gallery';
 

function App() {
  return (
     <>
     <Header/>
     <Routes> 
          <Route path="/" exact  element={<Home/>} />
          <Route path="/home" exact  element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/warehouse" element={<Warehouse/>} />
          <Route path="/products" element={<Products/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/gallery" element={<Gallery/>} />
      </Routes>
     <Footer/>
     </>
  );
}

export default App;
