import React from 'react'
import { Link } from "react-router-dom";
const Abt = () => {
  return (
    <>
    
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title mb-4">
                        <h5 className="position-relative d-inline-block text-primary text-uppercase">About Us</h5>
                        <h1 className="display-5 mb-0 logofont">Suresh Paper Pvt. Ltd.</h1>
                    </div>
                    <h4 className="text-body fst-italic mb-4"><span className="logofont1 logosize1">Suresh Paper Pvt. Ltd.</span> has been making its humble albeit substantial contribution during the last more than three decades. Being one of the most reputed Companies in this field.</h4>
                    <p className="mb-4">We have Warehouse/Converting Facilities located in Delhi-NCR and Mumbai. We are coming soon in Chennai.</p>
                    <div className="row g-3">
                        <div className="col-sm-6 wow zoomIn" data-wow-delay="0.3s">
                            <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>Best In Industry</h5>
                            <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>24/7 Customer Support</h5>
                        </div>
                        <div className="col-sm-6 wow zoomIn" data-wow-delay="0.6s">
                            <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>Professional Staff</h5>
                            <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>Fair Prices</h5>
                        </div>
                    </div>
                    <Link to="https://goo.gl/maps/aBg5qUxvgU34BJ4T6" target="_blank" className="btn btn-primary py-3 px-5 mt-4 wow zoomIn" data-wow-delay="0.6s">Reach Us</Link>
                </div>
                <div className="col-lg-5" id="dnone" >
                    <div className="position-relative  ">
                        <img className="position-absolute w-100 objfit rounded wow zoomIn" alt="sureshpaper" data-wow-delay="0.9s" src="images/abt.jpg"   />
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Abt