import React from 'react'
import { Link } from "react-router-dom";
const Slider = () => {
  return (
    <>
    <div className="container-fluid p-0">
        <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="w-100" src="images/g1.jpg" alt="sureshpaper" />
                    <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div className="p-3"  >
                            <h5 className="text-white text-uppercase mb-3 animated slideInDown">Welcome To </h5>
                            <h1 className="display-1 text-white mb-md-4 animated zoomIn logofont">Suresh Paper Pvt Ltd</h1>
                            <p><span className="logofonts">Suresh Paper Pvt. Ltd.</span> has been making its humble albeit substantial contribution during the last more than three decades.  </p>
                            <Link target="_blank" to="https://goo.gl/maps/aBg5qUxvgU34BJ4T6" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Reach Us</Link>
                            <Link to="/contact" className="btn btn-secondary py-md-3 px-md-5 animated slideInRight">Contact Us</Link>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src="images/g3.jpg" alt="sureshpaper" />
                    <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div className="p-3"  >
                            <h5 className="text-white text-uppercase mb-3 animated slideInDown">Welcome To</h5>
                            <h1 className="display-1 text-white mb-md-4 animated zoomIn logofont">Suresh Paper Pvt Ltd</h1>
                            <p><span className="logofonts">Suresh Paper Pvt. Ltd.</span> has been making its humble albeit substantial contribution during the last more than three decades.  </p>
                            <Link target="_blank" to="https://goo.gl/maps/aBg5qUxvgU34BJ4T6" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Reach Us</Link>
                            <Link to="/contact" className="btn btn-secondary py-md-3 px-md-5 animated slideInRight">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    </>
  )
}

export default Slider