import React from 'react'

const Gallery = () => {
  return (
     <>
     <div className="container-fluid py-5">
    <div className="container">
         <div className="row g-5">
            <div className="col-lg-12">
                    <div className="section-title mb-5">
                        <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Gallery</h5>
                    </div>
            </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item">
                        <div className="position-relative rounded-top zindex">
                            <img className="img-fluid rounded-top w-100" src="images/Standard%20newsprint%20paper.jpg" alt="sureshpaper" />
                        </div>
                       
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top zindex">
                            <img className="img-fluid rounded-top w-100" src="images/g1.jpg" alt="sureshpaper" />
                        </div>
                        
                    </div>
                </div>

                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top zindex">
                            <img className="img-fluid rounded-top w-100" src="images/g3.jpg" alt="sureshpaper" />
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top zindex">
                            <img className="img-fluid rounded-top w-100" src="images/g4.jpg" alt="sureshpaper" />
                        </div>
                         
                    </div>
                </div>

                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top zindex">
                            <img className="img-fluid rounded-top w-100" src="images/g5.jpg" alt="sureshpaper" />
                        </div>
                       
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top zindex">
                            <img className="img-fluid rounded-top w-100" src="images/g6.jpg" alt="sureshpaper" />
                        </div>
                       
                    </div>
                </div>


            </div>
        </div>
    </div>
<br/>
<br/> 
     </>
  )
}

export default Gallery