import React from 'react'

import { HashLink } from 'react-router-hash-link';
const Product = () => {
  return (
     <>
       <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="row g-5 mb-5">
            
            <div className="col-lg-12">
              <div className="section-title mb-5">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Products</h5>
                <h1 className="display-5 mb-0">We Offer The Best Quality Products</h1>
                <p className="mb-4"><span className="logofont1">Suresh Paper Pvt. Ltd.</span> has been making its humble 
                        albeit substantial contribution during the last more than three decades.
                         Being one of the most reputed Companies in this field.</p>
              </div>
              <div className="row g-5">

                <div className="col-md-4 service-item wow zoomIn" data-wow-delay="0.3s">
                  <div className="rounded-top overflow-hidden"> 
                    <img className="img-fluid rounded-top" src="images/Standard%20newsprint%20paper.jpg" alt="sureshpaper" />     
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                    <HashLink to="/products#one"><h4 className="m-0">Standard Newsprint<br/> Paper</h4></HashLink>
                  </div>
                </div>
                <div className="col-md-4 service-item wow zoomIn" data-wow-delay="0.6s">
                  <div className="rounded-top overflow-hidden">
                  <img className="img-fluid rounded-top" src="images/newspaperfeat.png" alt="sureshpaper" />
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                  <HashLink to="/products#two"><h4 className="m-0">SC Offset /Glazed Newsprint Paper</h4></HashLink>
                  </div>
                </div>
                <div className="col-md-4 service-item wow zoomIn" data-wow-delay="0.9s">
                  <div className="rounded-top overflow-hidden">
                  <img className="img-fluid rounded-top" src="images/magazine.jpeg" alt="sureshpaper" />
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                  <HashLink to="/products#three"><h4 className="m-0">Improved Newsprint</h4></HashLink>
                  </div>
                </div>


                <div className="col-md-4 service-item wow zoomIn" data-wow-delay="0.3s">
                  <div className="rounded-top overflow-hidden">
                  <img className="img-fluid rounded-top" src="images/writing.jpg" alt="sureshpaper" />
                               
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                  <HashLink to="/products#four"><h4 className="m-0">Low Weight Coated Paper (LWC)</h4></HashLink>
                         
                  </div>
                </div>
                <div className="col-md-4 service-item wow zoomIn" data-wow-delay="0.6s">
                  <div className="rounded-top overflow-hidden">
                  <img className="img-fluid rounded-top" src="images/art-paper-n-500x500.jpg" alt="sureshpaper" />
                               
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                  <HashLink to="/products#five"><h4 className="m-0">Art Paper (C2S)</h4></HashLink>
                         
                  </div>
                </div>
                <div className="col-md-4 service-item wow zoomIn" data-wow-delay="0.9s">
                  <div className="rounded-top overflow-hidden">
                  <img className="img-fluid rounded-top" src="images/Untitled-design-3.png" alt="sureshpaper" />
                            
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                  <HashLink to="/products#six"><h4 className="m-0">Cromo Paper (C1S)</h4></HashLink>
                          
                  </div>
                </div>


                <div className="col-md-4 service-item wow zoomIn" data-wow-delay="0.3s">
                  <div className="rounded-top overflow-hidden">
                  <img className="img-fluid rounded-top" src="images/kraft-paper-1534797505-4212260%20(2).jpg" alt="sureshpaper" />
                              
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                  <HashLink to="/products#seven"><h4 className="m-0">Kraft Paper</h4></HashLink>  
                          
                  </div>
                </div>
                <div className="col-md-4 service-item wow zoomIn" data-wow-delay="0.6s">
                  <div className="rounded-top overflow-hidden">
                  <img className="img-fluid rounded-top" src="images/H7b362c8ef1df4d47bdbad4ab66a9a8beY.jpg" alt="sureshpaper" />
                               
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                  <HashLink to="/products#eight"> <h4 className="m-0">SBS/FBB</h4></HashLink>
                       
                  </div>
                </div>
                <div className="col-md-4 service-item wow zoomIn" data-wow-delay="0.9s">
                  <div className="rounded-top overflow-hidden">
                  <img className="img-fluid rounded-top" src="images/c1s-label-500x500.jpg" alt="sureshpaper" />
                             
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                  <HashLink to="/products#nine"> <h4 className="m-0">SCK/CCK</h4></HashLink>
                         
                  </div>
                </div>


              </div>
            </div>
          </div>
          
        </div>
      </div>
     </>
  )
}

export default Product