import React from 'react'
import { Link } from "react-router-dom";
const Slide = () => {
  return (
     <>
      <div className="container-fluid bg-primary bg-appointment my-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="row gx-5">
                <div className="col-lg-12 py-5 text-center">
                    <div className="py-5">
                        <h1 className="display-5 text-white mb-4 logofont">Suresh Paper Pvt Ltd</h1>
                        <p className="text-white mb-0"><span className="logofont1">Suresh Paper Pvt. Ltd.</span> has been making its humble albeit substantial contribution
                             during the last more than three decades. Being one of the most reputed Companies
                              in this field, functioning from the country’s Capital, its reach, diversity
                               of product supply, reliability and credibility among its large number of 
                               clients and its ability to cater to the needs of the media,
                             especially small and medium newspapers and periodicals, is second to none.
                             <span className="logofont1">Suresh Paper Pvt. Ltd.</span> has developed a unique network of supply and import channels
                              from almost all major newsprint producing countries of the world: notable 
                              among them being North American countries like Canada & USA; European 
                              countries like Sweden, Norway, Finland & Germany, Russia, Far Eastern
                               countries like Korea, Singapore & Japan. Besides, we also supply
                                substantial quantity of indigenously produced newsprint to our
                                 clients. Having developed a systemic approach and time-bound 
                                 operational norms, we have the organizational and infrastructural
                                  capability of efficient time and functional management, port & customs
                                   operationalisation, road transport, insurance and other related functions
                                    thus making it possible for the imported product to reach at the doorstep
                                     of the Actual User in all major consuming centers of the country, with so
                                      to say, sharp-edged smoothness of a blade.</p>
                                      <Link to="/about"   className="btn btn-primary py-3 px-5 mt-4 wow zoomIn" data-wow-delay="0.6s">Read More</Link>
            
                    </div>
                </div>
               
            </div>
        </div>
    </div>
     </>
  )
}

export default Slide