import React from 'react'
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
const Header = () => {
  return (
     <>
     <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-2 py-lg-0">
     <Link to="/home" className="navbar-brand p-0">
        <img src="images/logo.png" alt="logo" style={{height:100, padding:5}} />
        <span id="slogo" className="logofont">Suresh Paper Pvt. Ltd.</span>
    </Link>      
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-0">
            <Link to="/home" className="nav-item nav-link">Home</Link>
                <div className="nav-item dropdown">
                <Link to="/" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">About Us</Link>
                    <div className="dropdown-menu m-0">
                      <Link to="/about" className="dropdown-item">About Us</Link>
                      <Link to="/warehouse" className="dropdown-item">Warehouse & Converting Unit</Link>
                    </div>
                </div>
                 <div className="nav-item dropdown">
                 <Link to="/products" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Products</Link>
                    <div className="dropdown-menu m-0">
                       <HashLink  to="/products#one" class="dropdown-item">Standard Newsprint Paper</HashLink >
                        <HashLink  to="/products#two" class="dropdown-item">SC Offset /Glazed Newsprint Paper</HashLink >
                        <HashLink  to="/products#three" class="dropdown-item">Improved Newsprint</HashLink >
                        <HashLink  to="/products#four" class="dropdown-item">Low Weight Coated Paper (LWC)</HashLink>
                        <HashLink   to="/products#five" class="dropdown-item">Art Paper (C2S)</HashLink >
                        <HashLink  nk to="/products#six" class="dropdown-item">Cromo Paper (C1S)</HashLink >
                        <HashLink  to="/products#seven" class="dropdown-item"> Kraft Paper </HashLink >
                        <HashLink  to="/products#eight" class="dropdown-item">SBS/FBB</HashLink >
                        <HashLink  to="/products#nine" class="dropdown-item">SCK/CCK</HashLink >
                    </div>
                </div>
                <Link to="/gallery" className="nav-item nav-link">Gallery</Link>
                <Link to="/contact" className="nav-item nav-link">Contact</Link>
                <Link target="_blank" to="https://sureshpaper.com/webmail" className="nav-item nav-link">Webmail</Link>

                
            </div>
        </div>
    </nav>
     </>
  )
}

export default Header