import React from 'react'

const Warehouse = () => {
  return (
     <>
     
     <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-7">
                    <div className="section-title mb-4">
                        <h5 className="position-relative d-inline-block text-primary text-uppercase">WAREHOUSING & CONVERTING UNIT</h5>
                    </div>
                    <h4 className="text-body fst-italic mb-4 textj">We have Warehouse/Converting Facilities located in Delhi, Mumbai and Tronica City . Our facilities  DELHI   offer an inside and outside sales team to service all of your paper needs. Our Head office is located in NEW DELHI and boasts more than 75,000 square foot warehouse, converting and office facility.</h4>
                    <div className="row g-3">
                        <div className="col-sm-12 wow zoomIn" data-wow-delay="0.3s">
                             <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>STANDARD NEWSPRINT PAPER</h5>
                             <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>SC OFFSET / GLAZED NEWSPRINT PAPER</h5>
                             <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>IMPROVED NEWSPRINT</h5>
                             <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>LOW WEIGHT COATED PAPER (LWC)</h5>
                             <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>ART PAPER (C2S)</h5>
                             <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>CROMO PAPER (C1S)</h5>
                             <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>KRAFT PAPER</h5>
                             <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>SBS/FBB</h5>
                             <h5 className="mb-3"><i className="fa fa-check-circle text-primary me-3"></i>SCK/CCK</h5>
                        </div>
                    </div> 
                          </div>
                <div className="col-lg-5" >
                    <div className="position-relative  ">
                        <img alt="sureshpaper" className="position-absolute w-100 objfit rounded wow zoomIn" data-wow-delay="0.9s" src="images/abt.jpg"   />
                    </div>
                </div>
            </div>
        </div>
    </div>
     
<br/>
<br/><br/>
     </>
  )
}

export default Warehouse