import React from 'react'
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
const Footer = () => {
  return (
     <>

<div className="vert-move">
<Link target="_blank" to="https://www.google.com/maps/dir/?api=1&amp;origin=&amp;destination=28.631239%2C77.241471" >
<img src="images/giphy.gif" alt="img"   className="phnicon phn1" />
</Link>   
 
</div>
<div className="vert-move1">
<Link target="_blank" to="https://api.whatsapp.com/send?phone=919873556622" >
<img src="images/WhatsApp.png" alt="img"   className="phnicon phn2" /> 
</Link> 
 
</div>
<div className="vert-move2">
<Link target="_blank" to="tel:+91-11-41509426" >
<img src="images/217887.png" alt="img"  className="phnicon phn3" />
</Link> 

 
</div>
 
 <div className="container-fluid bg-dark text-light py-5 wow fadeInUp mar75" data-wow-delay="0.3s"  >
        <div className="container pt-5">
            <div className="row g-5 pt-4">
                <div className="col-lg-3 col-md-6">
                    <h3 className="text-white mb-4">Quick Links</h3>
                    <div className="d-flex flex-column justify-content-start">
                        <Link className="text-light mb-2" to="/home"><i className="fa fa-arrow-right text-primary me-2"></i>Home</Link>
                        <Link className="text-light mb-2" to="/about"><i className="fa fa-arrow-right text-primary me-2"></i>About Us</Link>
                        <Link className="text-light mb-2" to="/products"><i className="fa fa-arrow-right text-primary me-2"></i>Our Products</Link>
                        <Link className="text-light mb-2" to="/gallery"><i className="fa fa-arrow-right text-primary me-2"></i>Gallery</Link>
                        <Link className="text-light" to="/contact"><i className="fa fa-arrow-right text-primary me-2"></i>Contact Us</Link>    
                           
                     </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h3 className="text-white mb-4">Our Products</h3>
                        <div className="d-flex flex-column justify-content-start">
                        <HashLink to="/products#one" className="text-light mb-2"><i className="fa fa-arrow-right text-primary me-2"></i> Standard Newsprint Paper</HashLink>
                        <HashLink to="/products#two" className="text-light mb-2"><i className="fa fa-arrow-right text-primary me-2"></i> SC Offset /Glazed Newsprint Paper</HashLink>
                        <HashLink to="/products#three" className="text-light mb-2"><i className="fa fa-arrow-right text-primary me-2"></i> Improved Newsprint</HashLink>
                        <HashLink to="/products#four" className="text-light mb-2"><i className="fa fa-arrow-right text-primary me-2"></i> Low Weight Coated Paper (LWC)</HashLink>
                        <HashLink to="/products#five" className="text-light mb-2"><i className="fa fa-arrow-right text-primary me-2"></i> Art Paper (C2S)</HashLink>
                        <HashLink to="/products#six" className="text-light mb-2"><i className="fa fa-arrow-right text-primary me-2"></i> Cromo Paper (C1S)</HashLink>
                        <HashLink to="/products#seven" className="text-light mb-2"><i className="fa fa-arrow-right text-primary me-2"></i> Kraft Paper </HashLink>
                        <HashLink to="/products#eight" className="text-light mb-2"><i className="fa fa-arrow-right text-primary me-2"></i> SBS/FBB</HashLink>
                        <HashLink to="/products#nine" className="text-light"><i className="fa fa-arrow-right text-primary me-2"></i> SCK/CCK</HashLink>
                         </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h3 className="text-white mb-4">Get In Touch</h3>
                    
                    <p className="mb-2"><i className="fa fa-map text-primary me-2"></i> F-24, Street No. 10, Main Parwana Road, Jagatpuri, Delhi-110051, India</p>
                    
                    <p className="mb-2"><i className="fa fa-map text-primary me-2"></i>C-6 & 7, Tronica City Sector, A-2 Loni Ghaziabad, Uttar Pradesh 201102 India</p>
                     
                    <p className="mb-2"><i className="fa fa-envelope text-primary me-2"></i>info@sureshpaper.com</p>
                    <p className="mb-2"><i className="fa fa-phone text-primary me-2"></i>
                    <Link className="text-white" to="tel:+91-7982321829">+91-7982321829</Link> 
                    </p>
                    
                </div>
                <div className="col-lg-3 col-md-6">
                    <h3 className="text-white mb-4">Reach Us</h3>
                    <div className="d-flex">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14007.824372608693!2d77.241481!3d28.631078000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfcd5e38de063%3A0x553f4066e64a6fbd!2s5%2C%20Bahadur%20Shah%20Zafar%20Marg%2C%20Balmiki%20Basti%2C%20Vikram%20Nagar%2C%20New%20Delhi%2C%20Delhi%20110002!5e0!3m2!1sen!2sin!4v1678373621537!5m2!1sen!2sin" width="100%" height="250"
                        loading="lazy"   title='map'> </iframe>
                        
                      </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid text-light py-4 bgfoot"  >
        <div className="container">
            <div className="row g-0">
                <div className="col-md-12 text-center text-md-start1">
                    <p className="mb-md-0">&copy; <Link className="text-white  logofont1"   to="/home">Suresh Paper Pvt. Ltd.
</Link> All Rights Reserved.</p>
                </div>
                
            </div>
        </div>
    </div>
    
    <HashLink to="#" className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i className="fa fa-arrow-up"></i></HashLink>

     </>
  )
}

export default Footer