import React from 'react'
import { Link } from 'react-router-dom'

const Contact = () => {
  return (
     <>
     <div  className="container-fluid py-5">
        <div  className="container">
            <div  className="row g-5">
                <div  className="col-xl-4 col-lg-6 wow slideInUp" data-wow-delay="0.1s">
                    <div  className="bg-light rounded h-100 p-5">
                        <div  className="section-title">
                            <h5  className="position-relative d-inline-block text-primary text-uppercase">Contact Us</h5>
                            <h1  className="display-6 mb-4">Feel Free To Contact Us</h1>
                        </div>
                        
                       
                        <div  className="d-flex align-items-center mb-2">
                            <i  className="bi bi-geo-alt fs-1 text-primary me-3"></i>
                            <div  className="text-start">
                                <h5  className="mb-0">Our Office</h5>
                                <span>  F-24, Street No. 10, Main Parwana Road, Jagatpuri, Delhi-110051, India</span>
                            </div>
                        </div>
                        
                        
                        <div  className="d-flex align-items-center mb-2">
                            <i  className="bi bi-geo-alt fs-1 text-primary me-3"></i>
                            <div  className="text-start">
                                <h5  className="mb-0">Branch Office</h5>
                                <span>C-6 & 7, Tronica City Sector, A-2 Loni Ghaziabad, Uttar Pradesh 201102 India</span>
                            </div>
                        </div>
                        <div  className="d-flex align-items-center mb-2">
                            <i  className="bi bi-envelope-open fs-1 text-primary me-3"></i>
                            <div  className="text-start">
                                <h5  className="mb-0">Email Us</h5>
                                <span>info@sureshpaper.com</span>
                            </div>
                        </div>
                        <div  className="d-flex align-items-center">
                            <i  className="bi bi-phone-vibrate fs-1 text-primary me-3"></i>
                            <div  className="text-start">
                                <h5  className="mb-0">Call Us</h5>
                                <span>
                                    <Link className='clrss' to="tel:+91-7982321829">+91-7982321829</Link><br/>
                                     
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div  className="col-xl-4 col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14007.824372608693!2d77.241481!3d28.631078000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfcd5e38de063%3A0x553f4066e64a6fbd!2s5%2C%20Bahadur%20Shah%20Zafar%20Marg%2C%20Balmiki%20Basti%2C%20Vikram%20Nagar%2C%20New%20Delhi%2C%20Delhi%20110002!5e0!3m2!1sen!2sin!4v1678373621537!5m2!1sen!2sin"  title='map' width="100%" height="450"     loading="lazy" ></iframe>
                    </div>
                <div  className="col-xl-4 col-lg-12 wow slideInUp" data-wow-delay="0.6s">
                
                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d4311.218781643345!2d77.26004422316377!3d28.786876286852145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDQ3JzEzLjEiTiA3N8KwMTUnNDYuMiJF!5e0!3m2!1sen!2sin!4v1679745244672!5m2!1sen!2sin" width="100%" height="450"   allowfullscreen=""  title='map' ></iframe>
                </div>
            </div>
        </div>
    </div>
     </>
  )
}

export default Contact